import React from 'react';
import { graphql } from 'gatsby';

import Helmet from 'react-helmet';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Hero from '../components/Hero';
import StyledMapWithAnInfoBox from '../components/GoogleMap';

const Contact = ({ data }) => (
  <Layout>
    <SEO
      meta_title={data.prismicContactPage.data.meta_title}
      meta_description={data.prismicContactPage.data.meta_description}
    />
    <Helmet>
      <script src="https://www.google.com/recaptcha/api.js" />
    </Helmet>
    <Hero title={data.prismicContactPage.data.hero_text.text} />
    <section className="bg-grey-lighter">
      <div className="container">
        <div className="flex flex-wrap justify-center">
          <div className="w-full md:w-4/5 lg:w-1/2">
            <form
              id="rr-contact"
              action="https://api.formbucket.com/f/buk_FqaUGkQa4efH5N9U5HVupDMg"
              method="post"
              target="_blank"
            >
              <input type="text" name="company" placeholder="Company" />
              <input type="text" name="fullname" placeholder="Full Name" required />
              <input type="text" name="phone" placeholder="Phone Number" />
              <input type="text" name="email" placeholder="Email Address" required />

              <textarea type="text" name="message" placeholder="Message" rows="8" cols="50" required />

              <input type="text" name="honeypot" className="honeypot" />
              <div
                id="g-recaptcha-container"
                className="g-recaptcha mb-2"
                data-sitekey="6Lca034UAAAAAJlC4Mtb8UptRVtMECZFiqTUNLxZ"
              />
              <button className="button primary" type="submit">
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
    <StyledMapWithAnInfoBox />
  </Layout>
);

export default Contact;

export const query = graphql`
  query contactPage {
    prismicContactPage {
      data {
        title {
          html
          text
        }
        hero_text {
          html
          text
        }
        meta_title
        meta_description
      }
    }
  }
`;
