import React from 'react';

const { compose, withProps, withStateHandlers } = require('recompose');
const { withScriptjs, withGoogleMap, GoogleMap, Marker } = require('react-google-maps');
const { InfoBox } = require('react-google-maps/lib/components/addons/InfoBox');

const markerOk = require('../images/rr-icon.png');

const StyledMapWithAnInfoBox = compose(
  withProps({
    googleMapURL:
      'https://maps.googleapis.com/maps/api/js?key=AIzaSyAZgQLYKnrwJ6J18V3OPDY0xf_Exk6UmmI&v=3.exp&libraries=geometry,drawing,places',
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `400px` }} />,
    mapElement: <div style={{ height: `100%` }} />,
    center: { lat: 39.66, lng: -79.998 },
  }),
  withStateHandlers(
    () => ({
      isOpen: false,
    }),
    {
      onToggleOpen: ({ isOpen }) => () => ({
        isOpen: !isOpen,
      }),
    }
  ),
  withScriptjs,
  withGoogleMap
)(props => (
  <GoogleMap defaultZoom={16} defaultCenter={props.center} defaultOptions={{ styles: '' }}>
    <Marker position={{ lat: 39.6613336, lng: -79.9973478 }} onClick={props.onToggleOpen} icon={{ url: markerOk }}>
      {props.isOpen && (
        <InfoBox onCloseClick={props.onToggleOpen} options={{ closeBoxURL: ``, enableEventPropagation: true }}>
          <div
            style={{
              backgroundColor: `white`,
              opacity: 0.75,
              padding: `12px`,
            }}
          >
            <div style={{ fontSize: `12px`, fontColor: `#08233B` }}>
              <p>
                RIDGE RUNNER PIPELINE COMMISSIONING SPECIALISTS <br />
                51 SCOTT’S RUN ROAD <br />
                MAIDSVILLE, WV 26541{' '}
              </p>
              <p>
                <a href="https://www.google.com/maps/dir//51+Scotts+Run+Road,+Maidsville,+WV+26541/@39.6613336,-79.9973531,17z/data=!4m9!4m8!1m0!1m5!1m1!1s0x88357bc570caaed1:0x24389a9de8f896d7!2m2!1d-79.9951591!2d39.6613336!3e0">
                  Directions
                </a>
              </p>
            </div>
          </div>
        </InfoBox>
      )}
    </Marker>
  </GoogleMap>
));

export default StyledMapWithAnInfoBox;
